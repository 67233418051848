export const ADD_SCHEDULE = 'ADD_SCHEDULE';
export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE';
export const DELETE_SCHEDULE = 'DELETE_SCHEDULE';
export const GET_ITEMSCHEDULES = 'GET_ITEMSCHEDULES';
export const GET_MONTHCALENDARITEMCOUNT = 'GET_MONTHCALENDARITEMCOUNT';
export const GET_DAILYSCHEDULE = 'GET_DAILYSCHEDULE';

export const reducer = (state, action) => {
	const { payload } = action;
	switch (action.type) {
		case GET_DAILYSCHEDULE:
			return {
				...state,
				dailySchedule: payload.dailySchedule,
			};
		case GET_MONTHCALENDARITEMCOUNT:
			return {
				...state,
				monthScheduledItems: payload.monthScheduledItems,
			};
		case ADD_SCHEDULE:
			return {
				...state,
				dailySchedule: [...payload.newItems, ...state.dailySchedule],
				monthScheduledItems: {
					...state.monthScheduledItems,
					[payload.dateKey]: state.monthScheduledItems[payload.dateKey]
						? state.monthScheduledItems[payload.dateKey] +
						  payload.newItems.length
						: payload.newItems.length,
				},
			};
		case UPDATE_SCHEDULE:
			return {
				...state,
				dailySchedule: [
					...state.dailySchedule.slice(
						0,
						state.dailySchedule.findIndex(
							(d) => d.id === payload.updatedItem.id
						)
					),
					{ ...payload.updatedItem },
					...state.dailySchedule.slice(
						state.dailySchedule.findIndex(
							(d) => d.id === payload.updatedItem.id
						) + 1
					),
				],
			};
		case DELETE_SCHEDULE:
			return {
				...state,
				dailySchedule: state.dailySchedule.filter(
					(data) => !payload.deletedItemKeys.includes(data.id)
				),
				monthScheduledItems: {
					...state.monthScheduledItems,
					[payload.dateKey]:
						state.monthScheduledItems[payload.dateKey] -
						payload.deletedItemKeys.length,
				},
			};
		default:
			return state;
	}
};

export const GET_ITEMDELIVERIES = 'GET_ITEMDELIVERIES';
export const GET_DELIVERIES = 'GET_DELIVERIES';
export const ADD_DELIVERY = 'ADD_DELIVERY';
export const EDIT_DELIVERY = 'EDIT_DELIVERY';
export const DELETE_DELIVERY = 'DELETE_DELIVERY';
export const RESET_DELIVERYDETAILS = 'RESET_DELIVERYDETAILS';

export const reducer = (state, action) => {
  switch (action.type) {
    case GET_ITEMDELIVERIES:
      return {
        ...state,
        itemDeliveryDetails: action.payload.itemDeliveryDetails,
      }
    case ADD_DELIVERY:
      return {
        ...state,
        itemDeliveryDetails: {
          ...state.itemDeliveryDetails,
          itemDelivered: action.payload.itemDelivered,
          itemQuantity: action.payload.itemQuantity,
          itemRemaining: action.payload.itemRemaining,
          itemDeliveries: [action.payload.newDelivery, ...state.itemDeliveryDetails.itemDeliveries]
        }
      }
    case EDIT_DELIVERY:
      return {
        ...state,
        itemDeliveryDetails: {
          ...state.itemDeliveryDetails,
          itemDelivered: action.payload.itemDelivered,
          itemQuantity: action.payload.itemQuantity,
          itemRemaining: action.payload.itemRemaining,
          itemDeliveries:
            [
              ...state.itemDeliveryDetails.itemDeliveries.slice(
                0, state.itemDeliveryDetails.itemDeliveries.findIndex(data =>
                  data.id === action.payload.updatedDelivery.id)),
              { ...action.payload.updatedDelivery },
              ...state.itemDeliveryDetails.itemDeliveries.slice(
                state.itemDeliveryDetails.itemDeliveries.findIndex(data =>
                  data.id === action.payload.updatedDelivery.id) + 1)
            ]
        }
      }
    case DELETE_DELIVERY:
      return {
        ...state,
        itemDeliveryDetails: {
          ...state.itemDeliveryDetails,
          itemDelivered: action.payload.itemDelivered,
          itemQuantity: action.payload.itemQuantity,
          itemRemaining: action.payload.itemRemaining,
          itemDeliveries: state.itemDeliveryDetails.itemDeliveries.filter(data =>
            data.id !== action.payload.id)
        }
      }
    case RESET_DELIVERYDETAILS:
      return {
        ...state,
        itemDeliveryDetails: []
      }
    case GET_DELIVERIES:
      return {
        ...state,
        deliveredItems : action.payload.deliveredItems
      }
    default:
      return state
  }
}
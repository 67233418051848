import React, { useState, lazy, useEffect, Fragment } from 'react';
import moment from 'moment';
import axios from 'axios';
import { DatePicker, Spin, Button, Tooltip, InputNumber, Radio } from 'antd';
import Layout from '../layout/Layout';

import { API } from '../config/config';
import { displayErrors, displayNotification } from '../config/display';
import { headers } from '../config/token';

const TableSales = lazy(() => import('../components/Sales/TableSales'));
const { RangePicker } = DatePicker;

const defaultData = {
  tableData: [],
  tableKeys: [],
}

const SalesView = props => {

  const defaultDate = day => moment().day(day).format("YYYY-MM-DD");
  const [dateRange, setDateRange] = useState([defaultDate(0), defaultDate(7)]);
  const [conversion, setConversion] = useState(50);
  const [summaryFormat, setSummaryFormat] = useState("none");
  const [data, setData] = useState(defaultData);
  const [spin, setSpin] = useState(false);
  
  const params = `from=${dateRange[0]}&to=${dateRange[1]}&conversion=${conversion}&summary=${summaryFormat}`;
  const fetchReport = () => {
    setSpin(true);
    axios.get(API + `cposms/po/reporting/sales?${params}`, headers())
      .then(res => {
        const { tableData, tableKeys } = res.data
        setData({ ...data, tableData, tableKeys });
        setSpin(false);

      })
      .catch(err => {
        displayErrors(err);
        setSpin(false);
      })

  }

  const exportSalesCSV = () => {
    setSpin(true);
    axios({
      url: `${API}cposms/po/reporting/export-sales/dl?from=${dateRange[0]}&to=${dateRange[1]}&conversion=${conversion}`,
      method: 'get',
      responseType: 'blob',
      ...headers()
    })
      .then(res => {
        displayNotification('success', 'File successfully generated');
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');
        link.setAttribute('download', `Sales_report_from_${dateRange[0]}_to_${dateRange[1]}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentElement.removeChild(link);
        setSpin(false);

      })
      .catch(err => {
        displayErrors(err);
        setSpin(false);
      })
  }

  useEffect(() => fetchReport(dateRange), [dateRange, conversion, summaryFormat]);

  const { tableData, tableKeys } = data;

  return (
    <Fragment>
      <br />
      <h2>SALES REPORT</h2>

      <RangePicker
        style={{ marginRight: 5, width: '25%' }}
        format="YYYY-MM-DD"
        value={[dateRange[0] ? moment(dateRange[0], 'YYYY-MM-DD') : null,
        dateRange[1] ? moment(dateRange[1], 'YYYY-MM-DD') : null]}
        onChange={(date, dateString) => setDateRange([...dateString])}
      />

      <Tooltip title="Conversion amount(for usd)" placement="right">
        <InputNumber
          style={{ marginRight: 5 }}
          min={1}
          value={conversion}
          onChange={val => setConversion(val ? val : 1)}
        />
      </Tooltip>

      <Tooltip title="Summary details for sales" placement="right">
        <Radio.Group
          onChange={e => setSummaryFormat(e.target.value)}
          style={{ marginRight: 5 }}
          defaultValue={summaryFormat} size="medium">
          <Radio.Button value="none">None</Radio.Button>
          <Radio.Button value="daily">Daily</Radio.Button>
          <Radio.Button value="weekly">Weekly</Radio.Button>
          <Radio.Button value="monthly">Monthly</Radio.Button>
        </Radio.Group>
      </Tooltip>

      <Tooltip title="Export to CSV" placement="right"><Button
        onClick={() => exportSalesCSV(dateRange)}
        shape="circle"
        className="bl-cl"
        style={{ marginRight: 5 }}
        icon="file-excel"></Button>
      </Tooltip>

      <Tooltip title="Refresh content" placement="right"><Button
        onClick={() => fetchReport(dateRange)}
        shape="circle"
        className="bl-cl"
        style={{ marginRight: 5 }}
        icon="sync"></Button>
      </Tooltip>

      <Spin spinning={spin}>
        <TableSales
          tableData={tableData}
          tableKeys={tableKeys}
        />
      </Spin>
    </Fragment>
  )
}

export default Layout(SalesView)

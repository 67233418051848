import React, { Fragment, useContext, useEffect, useState, lazy } from 'react';
import Layout from '../layout/Layout';
import { DelContext } from '../context/Deliveries/DeliveriesContext';
import { Utils } from '../context/UtilsContext';
import searchArray from '../components/searchArray';
import SearchAndRecordFilter from '../components/Filter/SearchAndRecordFilter';

const PoDeliveredItemsTable = lazy(() => import('../components/PoDelivery/PoDeliveredItemsTable'));
const DeliveredItemsFilter = lazy(() => import('../components/Filter/DeliveredItemsFilter'));

const DeliveredItems = props => {

  const { viewAllDelivered, deliveries, exportDeliveredItems } = useContext(DelContext);
  const { setLoading } = useContext(Utils);
  const [searchRecord, setSearchRecord] = useState({
    search: '',
    throttle: 500,
  });

  //filter
  const defaultFilter = {
    start: null,
    end: null,
  }
  const [filter, setFilter] = useState(defaultFilter);
  const onFilter = () => viewAllDelivered(setLoading, filter, searchRecord.throttle);
  const onResetFilter = () => {
    setFilter(defaultFilter);
    viewAllDelivered(setLoading, defaultFilter, searchRecord.throttle);
  };
  //end filter
  useEffect(() => {
    viewAllDelivered(setLoading, defaultFilter, searchRecord.throttle);
  }, []);

  //search & records
  const filteredDeliveries = searchArray(searchRecord.search, deliveries.deliveredItems, [
    'po_num', 'item_desc', 'code', 'date', 'partnum'
  ]);
  const refreshContent = (throttle = searchRecord.throttle) => viewAllDelivered(setLoading, filter, throttle);
  const exportRecordToCsv = () => exportDeliveredItems(setLoading, filter, searchRecord.throttle);
  //end search

  return (
    <Fragment>
      <br />
      <h2>DELIVERED ITEMS</h2>
      <SearchAndRecordFilter
        setFilter={setSearchRecord}
        filter={searchRecord}
        refreshContent={refreshContent}
        exportRecordToCsv={exportRecordToCsv}
      />

      <DeliveredItemsFilter
        filter={filter}
        setFilter={setFilter}
        onResetFilter={onResetFilter}
        onFilter={onFilter}
      />

      <PoDeliveredItemsTable
        deliveredItems={filteredDeliveries}
      />
    </Fragment>
  )
}

export default Layout(DeliveredItems)

const searchArray = (search, record = [], cols = []) => {
	return record.filter((data) => {
		let counter = 0;
		cols.forEach((col) => {
			if (Object.prototype.hasOwnProperty.call(data, col) && data[col] !== null)
				if (data[col].toUpperCase().indexOf(search.toUpperCase()) !== -1)
					counter += 1;
		});
		if (counter > 0) return true;

		return false;
	});
};

export default searchArray;

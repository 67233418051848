import React, {
	useEffect,
	useContext,
	lazy,
	Suspense,
	Fragment,
	useState,
} from 'react';

import Loader from '../components/Loader/Loader';
import { Button } from 'antd';
import { PoContext } from '../context/PurchaseOrder/PurchaseOrderContext';
import { Utils } from '../context/UtilsContext';
import PolistFilter from '../components/Filter/PolistFilter';

const PoList = lazy(() => import('../components/PurchaseOrder/PoList'));
// const PoForm = lazy(() => import('../components/PurchaseOrder/PoForm'));
const PurchaseOrderModal = lazy(() =>
	import('../components/PurchaseOrder/PurchaseOrderModal')
);

const PoDetailsView = ({ customers }) => {
	const [poModalConfig, setpoModalConfig] = useState({ view: false }); //adding new po
	const closeModal = () => setpoModalConfig({ view: false });
	const {
		purchaseOrder,
		getPO,
		cancelPo,
		userDetails,
		exportPoCsv,
		endorsePo,
	} = useContext(PoContext);
	const { setLoading } = useContext(Utils);
	//filter
	const defaultFilter = {
		search: '',
		sort: 'latest',
		customer: null,
		status: null,
		month: null,
		year: null,
	};
	const [filter, setFilter] = useState(defaultFilter);
	const [paginate, setPaginate] = useState({ page: 1, pageSize: 10 });
	const onFilter = () => getPO(setLoading, filter);
	const onResetFilter = () => {
		setFilter(defaultFilter);
		getPO(setLoading, defaultFilter);
	};
	//end filter

	const shouldFetchFromServer = ({ page, pageSize }) => {
		const totalData = page * pageSize;
		const currentDataLength = purchaseOrder.po.length; //
		const dataTotalLength = purchaseOrder.poLength;
		let shouldReload = false;
		if (
			totalData > currentDataLength &&
			(dataTotalLength > totalData ||
				(dataTotalLength > totalData - pageSize &&
					dataTotalLength > currentDataLength))
		) {
			shouldReload = true;
		}

		const shouldLoadAll =
			(totalData > dataTotalLength && dataTotalLength > totalData - pageSize) ||
			totalData === dataTotalLength
				? `&start=${currentDataLength}&end=${dataTotalLength}`
				: ``;

		return {
			shouldReload,
			newPage: Math.floor(currentDataLength / 1000) + 1,
			shouldLoadAll,
		};
	};

	const onPageChange = (page, pageSize) => {
		const { shouldReload, newPage, shouldLoadAll } = shouldFetchFromServer({
			page,
			pageSize,
		});
		if (shouldReload) {
			getPO(setLoading, filter, newPage, shouldLoadAll);
		}
		setPaginate({ page, pageSize });
	};

	const onSizeChange = (page, pageSize) => {
		const { shouldReload, newPage, shouldLoadAll } = shouldFetchFromServer({
			page,
			pageSize,
		});
		if (shouldReload) {
			getPO(setLoading, filter, newPage, shouldLoadAll);
		}
		setPaginate({ page, pageSize });
	};

	const refreshContent = () => {
		getPO(setLoading, defaultFilter);
		setPaginate({ page: 1, pageSize: 10 });
	};

	useEffect(() => {
		getPO(setLoading, defaultFilter);
	}, []);

	return (
		<Fragment>
			<br />
			<Suspense fallback={<Loader />}>
				<PolistFilter
					filter={filter}
					setFilter={setFilter}
					onFilter={onFilter}
					onResetFilter={onResetFilter}
					customers={customers}
				/>

				<Button
					className="bl-cl"
					onClick={() => setpoModalConfig({ view: true })}
					style={{ marginRight: 5 }}
					icon="form"
				>
					Add purchase order
				</Button>
				<Button
					className="bl-cl"
					onClick={() => exportPoCsv(setLoading, filter)}
					style={{ marginRight: 5 }}
					icon="file-excel"
				>
					Export to CSV
				</Button>
				<Button
					className="bl-cl"
					onClick={refreshContent}
					style={{ marginRight: 5 }}
					icon="sync"
				>
					Refresh content
				</Button>

				{/* add po */}
				{poModalConfig.view ? (
					<PurchaseOrderModal
						userType={userDetails.type}
						closeModal={closeModal}
						visible={poModalConfig.view}
						defaultValue={poModalConfig.defaultValue}
					/>
				) : null}
				{/* </Modal> */}
				<PoList
					userType={userDetails.type}
					setpoModalConfig={setpoModalConfig}
					cancelPo={cancelPo}
					customers={purchaseOrder.customers}
					poList={purchaseOrder.po}
					endorsePo={endorsePo}
					setLoading={setLoading}
					paginate={paginate}
					onPageChange={onPageChange}
					onSizeChange={onSizeChange}
					pageLength={purchaseOrder.poLength}
				/>
			</Suspense>
		</Fragment>
	);
};

export default PoDetailsView;

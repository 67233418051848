import React, { lazy, useContext, useEffect, useState, Suspense } from 'react';
import { Modal, Row, Col, Button, Descriptions, Spin } from 'antd';
import { DelContext } from '../../context/Deliveries/DeliveriesContext';
import { PoContext } from '../../context/PurchaseOrder/PurchaseOrderContext';

const PoDeliveredTable = lazy(() => import('./PoDeliveredTable'));
const PoDeliveredItemForm = lazy(() => import('./PoDeliveredItemForm'));

const PoViewDeliveredItem = (props) => {
  const { itemDeliveryDetails: { visible, id }, setItemDeliveryDetails } = props;
  const {
    deliveries,
    getItemDeliveries,
    userDetails,
    addItemDelivery,
    editItemDelivery,
    deleteDelivery,
    resetDeliveryDetails
  } = useContext(DelContext);
  const { updatePoItem } = useContext(PoContext);
  const [formConfig, setFormConfig] = useState({ visible: false });
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getItemDeliveries(id)
      .finally(() => setLoading(false));
  }, []);

  const {
    itemDeliveryDetails: {
      itemQuantity,
      itemDelivered,
      itemRemaining,
      item_id,
      itemDeliveries
    } } = deliveries;

  return (
    <Modal
      title="Delivery details"
      footer={null}
      width={formConfig.visible ? "80%" : "60%"}
      style={{ transition: 'width 200ms', top: 10 }}
      visible={visible}
      onCancel={() => {
        if(!isLoading) {
          setItemDeliveryDetails({ visible: false });
          resetDeliveryDetails();
        }
      }}
    >
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col style={{ display: formConfig.visible ? 'inline' : 'none' }} lg={{ span: 6 }}>
            <Suspense fallback={<div>Loading...</div>}>
              <PoDeliveredItemForm
                setFormConfig={setFormConfig}
                updatePoItem={updatePoItem}
                addItemDelivery={addItemDelivery}
                editItemDelivery={editItemDelivery}
                userType={userDetails.type}
                details={{ ...formConfig.details }}
              />
            </Suspense>
          </Col>
          <Col lg={{ span: formConfig.visible ? 18 : 24 }}>
            <Descriptions size="small" bordered={true} style={{ margin: "10px 0 10px 0" }}>
              <Descriptions.Item label="Total Quantity">
                {itemQuantity}
              </Descriptions.Item>
              <Descriptions.Item label="Total Delivered">
                {itemDelivered}
              </Descriptions.Item>
              <Descriptions.Item label="Total Remaining">
                {itemRemaining}
              </Descriptions.Item>
            </Descriptions>
            {!formConfig.visible
              ?
              itemRemaining !== 0
                ?
                <Button
                  onClick={() => setFormConfig({ visible: true, details: { itemRemaining, item_id } })}
                  style={{ marginBottom: 10 }}
                  icon="form">Add delivery</Button>
                : null
              : <Button
                className="red-cl"
                onClick={() => setFormConfig({ visible: false })}
                style={{ marginBottom: 10 }}
                icon="close">Close Form</Button>
            }
            <Suspense fallback={<div>Loading...</div>}>
              <PoDeliveredTable
                onRowClick={setFormConfig}
                itemDeliveries={itemDeliveries}
                details={deliveries.itemDeliveryDetails}
                deleteDelivery={deleteDelivery}
                setFormConfig={setFormConfig}
                updatePoItem={updatePoItem}
                userType={userDetails.type}
              />
            </Suspense>
          </Col>
        </Row>
      </Spin>
    </Modal>
  )
}

export default PoViewDeliveredItem

import React, { useState, useEffect, useContext } from "react";
import { Switch } from "antd";

import Layout from "../layout/Layout";

import PoItemView from "./PoItemView";
import PoDetailsView from "./PoDetailsView";
import { PoContext } from "../context/PurchaseOrder/PurchaseOrderContext";
import CustomerContext from "../context/Customer/CustomerContext";

const PoView = () => {
  const [showPO, setshowPO] = useState(true);
  const {
    getSelectOptions,
    purchaseOrder: { unitsOption },
  } = useContext(PoContext);
  const {
    customersVal: { customers, isLoaded },
    getCustomers,
  } = useContext(CustomerContext);

  useEffect(() => {
    if (unitsOption.length < 1) getSelectOptions();
    if (!isLoaded) getCustomers();
  }, []);

  return (
    <>
      <br />
      <h2>CUSTOMER'S PURCHASE ORDER{showPO ? "" : " ITEMS"}</h2>
      <Switch
        onChange={() => setshowPO(!showPO)}
        checkedChildren="Show PO"
        unCheckedChildren="Show Items"
        defaultChecked={showPO}
      />
      {showPO ? (
        <PoDetailsView customers={customers} />
      ) : (
        <PoItemView customers={customers} />
      )}
    </>
  );
};

export default Layout(PoView);

import React from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import PurchaseOrderContext from './context/PurchaseOrder/PurchaseOrderContext';
import DeliveriesContext from './context/Deliveries/DeliveriesContext';
import ScheduleContext from './context/Schedule/ScheduleContext';
import UtilsContext from './context/UtilsContext';
import { CustomerProvider } from './context/Customer/CustomerContext';

import Login from './views/Login';
import Error from './error/Error';
import './assets/css/Login.css';
import './App.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

import { routes } from './config/config';

library.add(fab, fas);

function App() {
  return (
    <Router>
      <Switch>
      
        <UtilsContext >
          <PurchaseOrderContext>
            <DeliveriesContext>
              <ScheduleContext>
                <CustomerProvider>
                  <Switch>
                    <Route path="/" render={prop => <Login {...prop} />} exact />
                    {routes.map((data, i) => {
                      return <Route
                        key={i}
                        path={data.path}
                        render={prop => <data.component {...prop} title={data.title} exact />} />
                    })}
                    <Route render={prop => <Error {...prop} />} exact />
                  </Switch>
                </CustomerProvider>
              </ScheduleContext>
            </DeliveriesContext>
          </PurchaseOrderContext>
        </UtilsContext>
      </Switch>
    </Router>
  );
}

export default App;

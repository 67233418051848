import React, { useRef } from 'react'
import PropTypes from 'prop-types';
import { Modal, Col, Row, Button } from 'antd';
import { Formik, Field, Form } from 'formik';
import { FormikDebug } from '@jbuschke/formik-antd';
import * as Yup from 'yup';

import nltsjson from './nationalities.json';

import { AntInput, AntSelect, AntInputNumber } from '../AntField'
import Confirm from '../Confirm';
const validateForm = Yup.object().shape({
  bi_name: Yup.string()
    .required('Business name is required')
    .matches(/^[a-zA-Z0-9-_ ]*$/, 'Special characters are not allowed')
    .transform(cv => cv ? cv : '')
    .max(100),
  bi_address: Yup.string()
    .required('Business address is required')
    .transform(cv => cv ? cv : '')
    .max(150),
  bi_nature: Yup.string()
    .required('Nature of business is required')
    .transform(cv => cv ? cv : '')
    .max(150),
  bi_type: Yup.string()
    .required('Business type is required')
    .transform(cv => cv ? cv : ''),
  // bi_lengthYears: Yup.number()
  //   .transform(cv => isNaN(cv) ? undefined : cv)
  //   .integer('Decimal is not allowed')
  //   .min(1, 'Must be greater than 0'),
  // bi_lengthMonths: Yup.number()
  //   .transform(cv => isNaN(cv) ? undefined : cv)
  //   .integer('Decimal is not allowed')
  //   .min(1, 'Must be greater than 0'),
  bi_yearsBusiness: Yup.number()
    .transform(cv => isNaN(cv) ? undefined : cv)
    .integer('Decimal is not allowed')
    .positive('Must be positive')
    .required('Years in business is required'),
  bi_contact: Yup.string()
    .required('Contact person is required')
    .matches(/^[a-zA-Z ]*$/, 'Only Alphabets are allowed')
    .transform(cv => cv ? cv : '')
    .max(50, 'Must not exceed 50 characters'),
  bi_contactposition: Yup.string()
    .when('bi_contact', {
      is: value => value !== undefined,
      then: Yup.string().required('Contact person position is required'),
      otherwise: Yup.string()
    })
    .transform(cv => cv ? cv : '')
    .max(50),
  bi_tel: Yup.string()
    .matches(/^[0-9-]+$/, 'Input a valid telephone number')
    .required('Telephone number is required')
    .transform(cv => cv ? cv : '')
    .max(20),
  bi_fax: Yup.string()
    .matches(/^[0-9-]+$/, 'Input a valid fax number')
    .nullable()
    .max(20),
  bi_sss: Yup.string()
    .max(30, 'Must not exceed 50 characters')
    .nullable(),
  bi_tin: Yup.string()
    .max(30, 'Must not exceed 50 characters')
    .nullable(),
  bi_email: Yup.string()
    .email('Email is not valid')
    .nullable()
    .max(50),
  bi_nationality: Yup.string()
    .required('Owner nationality is required')
  // owner_name: Yup.string()
  //   .nullable()
  //   .max(50),
  // owner_address: Yup.string()
  //   .nullable()
  //   .max(100),
  // owner_tel: Yup.string()
  //   .matches(/^[0-9 -]+$/, 'Input a valid telephone number')
  //   .nullable()
  //   .max(50),
  // owner_email: Yup.string()
  //   .email('Email is not valid')
  //   .nullable()
  //   .max(50)
});

const CustomersForm = ({
  visible, data, closeModal, addCustomer, updateCustomer, setLoading
}) => {

  const initialValue = useRef();
  
  if(Object.prototype.hasOwnProperty.call(data, 'id')) {
    initialValue.current = data;
  } else {
    initialValue.current = {
      bi_name: '',
      bi_address: '',
      bi_nature: '',
      bi_premises: 'OWNED',
      bi_type: '',
      bi_yearsBusiness: '',
      bi_contact : '',
      bi_contactposition : '',
      bi_tel : '',
      bi_fax : '',
      bi_sss : '',
      bi_tin : '',
      bi_email : '',
      bi_nationality: '',
    }
  }

  return (
    <Modal
      visible={visible}
      title="Customer Details"
      style={{
        top: 10
      }}
      width={1100}
      footer={null}
      onCancel={closeModal}
      destroyOnClose
    >
      <Formik
        initialValues={initialValue.current}
        validationSchema={validateForm}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if( Object.prototype.hasOwnProperty.call(values, 'id') )
            Confirm(updateCustomer, setSubmitting, values, setLoading, closeModal);
          else
            Confirm(addCustomer, setSubmitting, values, setLoading, resetForm);
        }}
        enableReinitialize
      >
        {({ values, isSubmitting, submitCount, handleReset, handleSubmit }) => {

          return (
            <Form>
              <Row gutter={16}>
                <Col xl={{ span: 12 }} >
                  <Field
                    component={AntInput}
                    label="COMPANY NAME"
                    name="bi_name"
                    value={values.bi_name}
                    submitCount={submitCount}
                    placeholder="Required field"
                  />

                  <Field
                    component={AntInput}
                    label="COMPANY ADDRESS"
                    name="bi_address"
                    value={values.bi_address}
                    submitCount={submitCount}
                    placeholder="Required field"
                  />

                  <Field
                    component={AntInput}
                    label="NATURE OF BUSINESS"
                    name="bi_nature"
                    value={values.bi_nature}
                    submitCount={submitCount}
                    placeholder="Required field"
                  />

                  <Field
                    component={AntSelect}
                    label="BUSINESS PREMISES"
                    name="bi_premises"
                    value={values.bi_premises}
                    selectOptions={['OWNED', 'RENTED']}
                    submitCount={submitCount}
                  />

                  <Field
                    component={AntSelect}
                    label="BUSINESS TYPE"
                    name="bi_type"
                    value={values.bi_type}
                    selectOptions={['CORPORATION', 'SINGLE PROPRIETORSHIP', 'PARTNERSHIP']}
                    submitCount={submitCount}
                  />

                  <Field
                    component={AntInputNumber}
                    label="NO. OF YEARS IN BUSINESS"
                    name="bi_yearsBusiness"
                    value={values.bi_yearsBusiness}
                    submitCount={submitCount}
                    min={1}
                  />

                  <Field
                    component={AntInput}
                    label="TELEPHONE NO. "
                    name="bi_tel"
                    value={values.bi_tel}
                    submitCount={submitCount}
                    placeholder="Required field"
                  />
                </Col>

                {/* 2nd column */}
                <Col xl={{ span: 12 }} >
                  <Field
                    component={AntInput}
                    label="FAX NO. "
                    name="bi_fax"
                    value={values.bi_fax}
                    submitCount={submitCount}
                  />

                  <Field
                    component={AntInput}
                    label="SSS NO. "
                    name="bi_sss"
                    value={values.bi_sss}
                    submitCount={submitCount}
                  />
                  <Field
                    component={AntInput}
                    label="TIN NO. "
                    name="bi_tin"
                    value={values.bi_tin}
                    submitCount={submitCount}
                  />

                  <Field
                    component={AntInput}
                    label="EMAIL ADDRESS "
                    name="bi_email"
                    value={values.bi_email}
                    submitCount={submitCount}
                  />

                  <Field
                    component={AntInput}
                    label="CONTACT PERSON"
                    name="bi_contact"
                    value={values.bi_contact}
                    submitCount={submitCount}
                    placeholder="Required field"
                  />

                  <Field
                    component={AntInput}
                    label="POSITION"
                    name="bi_contactposition"
                    value={values.bi_contactposition}
                    submitCount={submitCount}
                  />

                  <Field
                    component={AntSelect}
                    label="OWNER NATIONALITY"
                    name="bi_nationality"
                    value={values.bi_nationality}
                    selectOptions={nltsjson.nationalities}
                    submitCount={submitCount}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                  />
                </Col>
              </Row>
{/* 
              <br />
              <h2>OWNER INFORMATION</h2>
              <Divider></Divider>

              <Row>
                <Col xl={{ span: 12 }} >
                  <Field
                    component={AntInput}
                    label="NAME"
                    name="owner_name"
                    value={values.owner_name}
                    submitCount={submitCount}
                    placeholder="Required field"
                  />
                </Col>
                <Col xl={{ span: 12 }} >
                  <Field
                    component={AntInput}
                    label="TELEPHONE NO. "
                    name="owner_tel"
                    value={values.owner_tel}
                    submitCount={submitCount}
                    placeholder="Required field"
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={{ span: 12 }} >
                  <Field
                    component={AntInput}
                    label="ADDRESS"
                    name="owner_address"
                    value={values.owner_address}
                    submitCount={submitCount}
                    placeholder="Required field"
                  />
                </Col>
                <Col xl={{ span: 12 }} >
                  <Field
                    component={AntInput}
                    label="EMAIL"
                    name="owner_email"
                    value={values.owner_email}
                    submitCount={submitCount}
                    placeholder="Required field"
                  />
                </Col>
              </Row>
              <br />
              <h2>BANK REFERENCES</h2>
              <Divider></Divider>

              <FieldArray
                name="bank_references">
                {({ push, remove }) => (
                  <>
                    <Button
                      onClick={() => push({
                        name: '',
                        accountname: '',
                        branchname: '',
                        accountnum: ''
                      })}
                    >ADD BANK</Button>
                    {values.bank_references && values.bank_references.length > 0
                      ? values.bank_references.map((bank, index) => (
                        <div key={index}>
                          <div className="array-row">
                            <Button size="small" onClick={() => remove(index)} type="danger" icon="delete">REMOVE</Button>
                          </div>
                          <Row>
                            <Col xl={{ span: 12 }} >
                              <Field
                                component={AntInput}
                                label="BANK NAME"
                                name={`bank_references.${index}.name`}
                                value={bank.name}
                                submitCount={submitCount}
                                placeholder="Required field"
                              />
                            </Col>
                            <Col xl={{ span: 12 }} >
                              <Field
                                component={AntInput}
                                label="ACCOUNT NAME"
                                name={`bank_references.${index}.accountname`}
                                value={bank.accountname}
                                submitCount={submitCount}
                                placeholder="Required field"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xl={{ span: 12 }} >
                              <Field
                                component={AntInput}
                                label="BRANCH"
                                name={`bank_references.${index}.branchname`}
                                value={bank.branchname}
                                submitCount={submitCount}
                                placeholder="Required field"
                              />
                            </Col>
                            <Col xl={{ span: 12 }} >
                              <Field
                                component={AntInput}
                                label="ACCOUNT NO."
                                name={`bank_references.${index}.accountnum`}
                                value={bank.accountnum}
                                submitCount={submitCount}
                                placeholder="Required field"
                              />
                            </Col>
                          </Row>
                        </div>
                      ))

                      : <Empty />}
                  </>
                )
                }
              </FieldArray>

              <br />
              <h2>BUSINESS DOCUMENTS SUBMITTED</h2>
              <Divider></Divider>

              <Row>
                <Col xl={{ span: 6, offset: 2 }} >
                  <Field
                    component={AntCheckbox}
                    checkLabel="SEC Certificate"
                    name="docs_sec"
                    value={values.docs_sec}
                    checked={values.docs_sec}
                    submitCount={submitCount}
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={{ span: 6, offset: 2 }}>
                  <Field
                    component={AntCheckbox}
                    checkLabel="DTI Reg/ Mayor's Permit"
                    name="docs_dti"
                    value={values.docs_dti}
                    checked={values.docs_dti}
                    submitCount={submitCount}
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={{ span: 6, offset: 2 }}>
                  <Field
                    component={AntCheckbox}
                    checkLabel="Financial Statements"
                    name="docs_fs"
                    value={values.docs_fs}
                    checked={values.docs_fs}
                    submitCount={submitCount}
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={{ span: 6, offset: 2 }}>
                  <Field
                    component={AntCheckbox}
                    checkLabel="Articles of Incorporation"
                    name="docs_aoi"
                    value={values.docs_aoi}
                    checked={values.docs_aoi}
                    submitCount={submitCount}
                  />
                </Col>
              </Row>

              <br />
              <h2>BUSINESS DOCUMENTS SUBMITTED</h2>
              <Divider></Divider>
              <Row>
                <Col xl={{ span: 6, offset: 2 }} >
                  <Field
                    component={AntCheckbox}
                    checkLabel="Corrugated box"
                    name="npd_cb"
                    value={values.npd_cb}
                    checked={values.npd_cb}
                    submitCount={submitCount}
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={{ span: 6, offset: 2 }} >
                  <Field
                    component={AntCheckbox}
                    checkLabel="Foams"
                    name="npd_foam"
                    value={values.npd_foam}
                    checked={values.npd_foam}
                    submitCount={submitCount}
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={{ span: 6, offset: 2 }}>
                  <Field
                    component={AntCheckbox}
                    checkLabel="Danpla"
                    name="npd_danpla"
                    value={values.npd_danpla}
                    checked={values.npd_danpla}
                    submitCount={submitCount}
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={{ span: 6, offset: 2 }}>
                  <Field
                    component={AntCheckbox}
                    checkLabel="Wood/Paper Pallet"
                    name="npd_pp"
                    value={values.npd_pp}
                    checked={values.npd_pp}
                    submitCount={submitCount}
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={{ span: 6, offset: 2 }}>
                  <Field
                    component={AntInput}
                    placeholder="Others"
                    name="npd_others"
                    value={values.npd_others}
                    checked={values.npd_others}
                    submitCount={submitCount}
                  />
                </Col>
              </Row> */}
              <div
                className="ant-modal-footer"
                style={{ margin: '0 -20px 0 -20px' }}
              >
                <Button onClick={handleReset}>Reset</Button>
                <Button
                  icon="save"
                  disabled={isSubmitting}
                  type="primary"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
              {/* <FormikDebug /> */}
            </Form>
          )
        }}
        </Formik>
    </Modal>
  )
}

CustomersForm.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  addCustomer: PropTypes.func.isRequired,
  updateCustomer: PropTypes.func.isRequired,
}
export default CustomersForm

export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';

export const reducer = (state = {}, action) => {
  const { payload, type } = action;
  switch(type){
    case GET_CUSTOMERS:
      return {
        ...state,
        customers: payload.customers,
        isLoaded: true,
      }
    case ADD_CUSTOMER:
      return {
        ...state,
        customers: [payload.newCustomer, ...state.customers],
      }
    case UPDATE_CUSTOMER:
      return {
        ...state,
        customers: [
          ...state.customers.slice(
            0,
            state.customers.findIndex(
              customer => customer.id === payload.newCustomer.id
            )
          ),
          payload.newCustomer,
          ...state.customers.slice(
            state.customers.findIndex(
              customer => customer.id === payload.newCustomer.id
            ) + 1
          ),
        ]
      }
    case DELETE_CUSTOMER:
      return {
        ...state,
        customers: state.customers.filter((customer) => customer.id !== payload.id),
      }
    default:
      return state;
  }
}
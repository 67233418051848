import React, { useEffect, useState } from 'react';
import { Modal, Tree, Result, Spin } from 'antd';

import axios from 'axios';
import { displayErrors } from '../../config/display';
import { headers } from '../../config/token';
import { API } from '../../config/config';

const OverallDetailsModal = props => {

  const {
    setOverallDetails,
    overallDetails: {
      visible,
      id,
      item
    },
  } = props;

  const [tree, setTrees] = useState({ treeData: [], treeDataParentKeys: [] });
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios.get(API + `cposms/poitems/overall/${id}`, headers())
      .then(res => {

        const { treeData, treeDataParentKeys } = res.data;
        setTrees({
          treeData,
          treeDataParentKeys
        })

      })
    .catch(err => displayErrors(err))
    .finally(() => setLoading(false));

  }, []);

  return (
    <Modal
      style={{ top: 20 }}
      title={"DETAILS FOR " + item.toString().toUpperCase()}
      visible={visible}
      width="75%"
      onCancel={() => isLoading ? null : setOverallDetails({ visible: false })}
      footer={null}
    >
      <Spin spinning={isLoading}>
        {tree.treeData.length > 0 ?
          <Tree
            blockNode={true}
            // defaultExpandedKeys={tree.treeDataParentKeys}
            defaultExpandAll={true}
            treeData={tree.treeData}
            showLine
          >

          </Tree>
          :
          <Result title="NO JOB ORDER" status="info" />}
      </Spin>
      
    </Modal>
  )
}

export default OverallDetailsModal

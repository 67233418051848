export const GET_POITEM = 'GET_POITEM';
export const GET_PO = 'GET_PO';
export const ADD_PO = 'ADD_PO';
export const UPDATE_PO = 'UPDATE_PO';
export const CANCEL_PO = 'CANCEL_PO';
export const FETCH_OPTIONPO = 'FETCH_OPTIONPO';
export const UPDATE_POITEM = 'UPDATE_POITEM';
export const GET_ITEMSCHEDULE = 'GET_ITEMSCHEDULE';
export const ENDORSE_PO = 'ENDORSE_PO';

export const reducer = (state, action) => {
	switch (action.type) {
		case FETCH_OPTIONPO:
			return {
				...state,
				unitsOption: action.payload.unitsOption,
			};
		case GET_POITEM:
			return {
				...state,
				poItems:
					action.payload.page < 2
						? action.payload.poItems
						: state.poItems.concat(action.payload.poItems),
				poItemsLength: action.payload.poItemsLength,
			};
		case GET_PO:
			return {
				...state,
				po:
					action.payload.page < 2
						? action.payload.po
						: state.po.concat(action.payload.po),
				poLength: action.payload.poLength,
			};
		case ADD_PO:
			return {
				...state,
				po: [action.payload.newItem, ...state.po],
			};
		case UPDATE_PO:
			return {
				...state,
				po: [
					...state.po.slice(
						0,
						state.po.findIndex((d) => d.id === action.payload.newItem.id)
					),
					{ ...action.payload.newItem },
					...state.po.slice(
						state.po.findIndex((d) => d.id === action.payload.newItem.id) + 1
					),
				],
			};
		case CANCEL_PO:
			return {
				...state,
				po: state.po.filter((d) => d.id !== action.payload.id),
			};
		case UPDATE_POITEM:
			return {
				...state,
				poItems: [
					...state.poItems.slice(
						0,
						state.poItems.findIndex((d) => d.id === action.payload.newItem.id)
					),
					{ ...action.payload.newItem },
					...state.poItems.slice(
						state.poItems.findIndex((d) => d.id === action.payload.newItem.id) +
							1
					),
				],
			};
		case GET_ITEMSCHEDULE:
			return {
				...state,
				itemSchedule: action.payload.itemSchedule,
			};
		case ENDORSE_PO:
			return {
				...state,
				po: [
					...state.po.slice(
						0,
						state.po.findIndex((d) => d.id === action.payload.id)
					),
					{
						...state.po[state.po.findIndex((d) => d.id === action.payload.id)],
						isEndorsed: 1,
					},
					...state.po.slice(
						state.po.findIndex((d) => d.id === action.payload.id) + 1
					),
				],
			};
		default:
			return state;
	}
};

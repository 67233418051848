import React, { createContext, useReducer } from 'react';
import axios from 'axios';
import { headers } from '../../config/token';
import { API } from '../../config/config';
import { displayNotification, displayErrors } from '../../config/display';

import {
	reducer,
	GET_MONTHCALENDARITEMCOUNT,
	GET_DAILYSCHEDULE,
	ADD_SCHEDULE,
	UPDATE_SCHEDULE,
	DELETE_SCHEDULE,
} from './ScheduleReducer';

export const SchedContext = createContext({});

const ScheduleContext = ({ children }) => {
	const initialState = {
		monthScheduledItems: [],
		dailySchedule: [],
	};

	const [sched, dispatch] = useReducer(reducer, initialState);

	const getMonthlySchedItemQty = (setLoad, date) => {
		setLoad(true);
		axios
			.get(API + `cposms/poitems/schedules?date=${date}`, headers())
			.then((res) => {
				const { monthScheduledItems } = res.data;

				dispatch({
					type: GET_MONTHCALENDARITEMCOUNT,
					payload: {
						monthScheduledItems,
					},
				});

				setLoad(false);
			})
			.catch((err) => {
				displayErrors(err);
				setLoad(false);
			});
	};

	const getDailySchedule = (date) => {
		axios
			.get(API + `cposms/poitems/schedules/${date}/item`, headers())
			.then((res) => {
				const { dailySchedule } = res.data;

				dispatch({
					type: GET_DAILYSCHEDULE,
					payload: {
						dailySchedule,
					},
				});
			})
			.catch((err) => displayErrors(err));
	};

	const getOpenItems = (date) => {
		return axios.get(API + `cposms/option/openitems?date=${date}`, headers());
	};

	const addItemToSchedule = (
		values,
		date,
		openItems,
		setOpenItems,
		setTableLoad
	) => {
		setTableLoad(true);
		const payload = {
			items: values,
			date,
		};
		const dateKey = 'fy' + date.toString().replace(/\-/g, '');
		axios
			.post(API + `cposms/poitems/schedules`, payload, headers())
			.then((res) => {
				const { errors, newItems, itemKeys } = res.data;

				if (itemKeys.length > 0) {
					dispatch({
						type: ADD_SCHEDULE,
						payload: {
							newItems,
							dateKey,
						},
					});

					setOpenItems(openItems.filter((data) => !itemKeys.includes(data.id)));
					displayNotification(
						'success',
						`Successfully added ${itemKeys.length} item/s for ${date} schedule`
					);
				}

				if (errors.length > 0) {
					errors.forEach((error) => {
						displayNotification('error', error);
					});
				}
			})
			.catch((err) => displayErrors(err))
			.finally(() => setTableLoad(false));
	};

	const updateItemSchedule = (value) => {
		value.quantity = parseInt(value.quantity);

		dispatch({
			type: UPDATE_SCHEDULE,
			payload: {
				updatedItem: value,
			},
		});

		if (value.oldVal !== value.quantity) {
			axios
				.put(API + `cposms/poitems/schedules/${value.id}`, value, headers())
				.catch((err) => displayErrors(err));
		}
	};

	const deleteItemSchedule = (
		value,
		date,
		setTableLoad,
		setSelectedItemSchedule,
		defaultVal
	) => {
		setTableLoad(true);
		const ids = value.join(',');
		const dateKey = 'fy' + date.toString().replace(/\-/g, '');

		axios
			.delete(API + `cposms/poitems/schedules/${ids}?date=${date}`, headers())
			.then((res) => {
				const { message } = res.data;

				dispatch({
					type: DELETE_SCHEDULE,
					payload: {
						dateKey,
						deletedItemKeys: value,
					},
				});

				displayNotification('success', message);
				setTableLoad(false);
				setSelectedItemSchedule(defaultVal);
			})
			.catch((err) => {
				displayErrors(err);
				setTableLoad(false);
			});
	};

	const exportDailyScheduleCsv = (setLoading, date) => {
		setLoading(true);
		axios({
			url: API + `cposms/poitems/schedules/export-csv/dl?date=${date}`,
			method: 'get',
			responseType: 'blob',
			...headers(),
		})
			.then((res) => {
				displayNotification('success', 'File successfully generated');
				const url = window.URL.createObjectURL(new Blob([res.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('target', '_blank');
				link.setAttribute('download', `POItemSchedule_${date}.xlsx`);
				document.body.appendChild(link);
				link.click();
				link.parentElement.removeChild(link);
				setLoading(false);
			})
			.catch((err) => {
				displayErrors(err);
				setLoading(false);
			});
	};

	const exportDailySchedulePDF = (date) => {
		axios({
			url: API + `cposms/poitems/schedules/export-pdf/dl?date=${date}`,
			method: 'get',
			responseType: 'blob',
			...headers(),
		})
			.then((res) => {
				displayNotification('success', 'File successfully generated');
				//Create a Blob from the PDF Stream
				const file = new Blob([res.data], { type: 'application/pdf' });
				//Build a URL from the file
				const fileURL = URL.createObjectURL(file);
				//Open the URL on new Window
				window.open(fileURL);
			})
			.catch((err) => displayErrors(err));
	};

	return (
		<SchedContext.Provider
			value={{
				sched,
				getMonthlySchedItemQty,
				getDailySchedule,
				getOpenItems,
				addItemToSchedule,
				updateItemSchedule,
				deleteItemSchedule,
				exportDailyScheduleCsv,
				exportDailySchedulePDF,
			}}
		>
			{children}
		</SchedContext.Provider>
	);
};

export default ScheduleContext;
